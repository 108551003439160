'use client'

import type { ButtonProps } from ".";

import { default as ButtonComponent } from "@components/atoms/button"
import useQueryParams from "@hooks/useQueryParams"

const Button = ({ children, dark = false, ...props }: ButtonProps) => {
    const { setQueryParam, queryParams } = useQueryParams();

    const handleClick = () => setQueryParam('filter', !queryParams.get('filter') ? '1' : null);

    return (
        <ButtonComponent
            className={`me-0  me-xl-auto  flex-fill  flex-xl-grow-0  rounded-0  icon--transition ${(queryParams.get('filter') && dark) ? 'icon--white' : ''}`}
            onClick={handleClick} variant={(queryParams.get('filter') && dark) ? 'dark' : 'outline-dark'}
            icon={'filter'}
            iconWidth={'sm'}

            {...props}
        >
            {children}
        </ButtonComponent>
    )
}

export default Button

