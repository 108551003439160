'use client'

import { useState } from "react"
import useQueryParams from "@hooks/useQueryParams"

const Limiter = ({ value, label }: { value: string | number, label: string }) => {
    const { setQueryParam } = useQueryParams();

    const [active, setActive] = useState<string>()

    const handleChange = (limit: string) => {
        setQueryParam('limit', value != limit ? limit : null);
        setActive(limit)
    }

    return (
        <div className={'d-flex gap-3 align-items-end'}>
            <label htmlFor={'limiter'} className={'form-label'}>{label}</label>
            <select id={'limiter'} className={'catalog-page-limit-select text-nowrap form-select'} defaultValue={value} value={active} onChange={(e) => handleChange(e.currentTarget.value)}>
                {[8, 16, 24, 48].map((value, index) => (
                    <option key={'limiter' + index} value={value}>{value}</option>
                ))}
            </select>
        </div>

    )
}

export default Limiter

