import type { FC, PropsWithChildren } from 'react';
import type { FormCheckProps } from '.';

import BsFormCheck from 'react-bootstrap/FormCheck';

const FormCheck: FC<FormCheckProps> = ({ children, ...props }: PropsWithChildren<FormCheckProps>) => {
  return <BsFormCheck {...props}>{children}</BsFormCheck>;
};

export default FormCheck;
