'use client'

import { type AddToWishlistProps } from '.';

import Button from '@components/atoms/button';
import { useWishlistContext } from '@context/wishlist';
import { useEffect, useState } from 'react';

const AddToWishlist = ({ productId, name, variant, className }: AddToWishlistProps) => {

    const { products, addProduct, removeProduct } = useWishlistContext()

    const [is, setIs] = useState<boolean>(false)

    const t = (str: string, _?: any): string => str

    useEffect(() => setIs(() => undefined !== products[productId]), [products, productId])

    return (
        <Button
            icon={is ? 'wishlist-checked' : 'wishlist'}
            iconWidth={'sm'}
            iconVariant={'secondary'}

            variant={'white'}

            className={className ?? 'px-2'}

            onClick={() => false === is ? addProduct(productId) : removeProduct(productId)}

            aria-label={t('Add to Wishlist', { name })}
        >
            {'extended' === variant && <span>{t('Merkliste')}</span>}
        </Button>
    );
};

export default AddToWishlist;
