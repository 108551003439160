'use client'

import useQueryParams from "@hooks/useQueryParams"
import { useEffect, useState } from "react"
import FormCheck from "@components/atoms/form-check";

const Availability = ({ label, checked: defaultChecked = false }: { label: string, checked?: boolean }) => {
    const { setQueryParam, queryParams } = useQueryParams();

    const [checked, setChecked] = useState<boolean>(defaultChecked)

    useEffect(() => {
        setChecked(!!queryParams.get('available'))
    }, [queryParams])

    const handleChange = (checked: boolean) => {
        setQueryParam('available', checked ? '1' : null);
        setChecked(checked)
    }

    return (
        <FormCheck type={'checkbox'} id={`category_availability`} label={label} checked={checked} onChange={(e) => handleChange(e.target.checked)} className={'d-none d-md-inline-flex  align-items-center  gap-1  mh-auto  mb-0'} />
    )
}

export default Availability

