'use client'

import type { TrustedShopsProps } from ".";

import Script from "next/script";

const updateScript = (tsid: string) => {
    document.querySelector("div[id^=trustbadge-topbar-container]")?.remove()
    document.querySelector("script[id=trustedshops]")?.remove();
    document.querySelector("script[data-type='trustbadge-business-logic']")?.remove();
    document.querySelector("div[id=trustbadgeCustomContainer]")?.replaceChildren()

    var _tsid = tsid;
    const script = document.createElement("script")

    script.id = 'trustedshops'
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//widgets.trustedshops.com/js/' + _tsid + '.js';

    script.setAttribute("data-desktop-enable-custom", "true");
    script.setAttribute("data-mobile-enable-topbar", "true");

    script.async = true

    document.body.appendChild(script)
}


const TrustedShops = ({ tsid, variant, ...props }: TrustedShopsProps) => (
    <>
        <Script
            id={`trustedshops`}

            dangerouslySetInnerHTML={{ __html: `console.log('Trustedshops loaded')` }}

            data-desktop-enable-custom="true"
            data-mobile-enable-topbar="true"

            onReady={() => {
                const { trustbadge }: any = window
                trustbadge?.remove()

                updateScript(tsid)
            }}
        />
        <div key={'trusted_badge_container' + variant} id={`trustbadgeCustomContainer${'custom_reviews' === variant ? '' : 'finish' === variant ? 'Finish' : ''}`} {...props}></div>
    </>
);

export default TrustedShops;
