import { default as LinkComponent } from 'next/link';
import { type LinkProps } from '.';

export const sanitizeLink = (href: string) => ((!href?.startsWith('/') && !href?.startsWith('mailto') && !href?.startsWith('tel') && !href?.startsWith('http')) ? '/' : '') + (href ?? '/')

const Link = ({ children, href: propsHref, prefetch = false, ...props }: LinkProps) => {

    let href = propsHref
    if ('string' === typeof href) {
        href = sanitizeLink(href)
    } else if ('object' === typeof href) {
        href.pathname = sanitizeLink(href.pathname)
    }

    return <LinkComponent href={href ?? '/'} prefetch={prefetch} className={'text-decoration-none'} {...props}>{children}</LinkComponent>
}

export default Link;
