'use client'

import { useEffect, useState } from 'react';
import { type AddToCompareButtonProps } from '.';
import Button from '@components/atoms/button';
import { useCompareContext } from '@context/compare';

const AddToCompareButton = ({ productId, name, variant, className }: AddToCompareButtonProps) => {

    const t = (str: string, _?: any): string => str

    const { products, dispatch, setShow } = useCompareContext()

    const [is, setIs] = useState<boolean>(false)
    useEffect(() => setIs(() => undefined !== products[productId]), [products, productId])

    const handleClick = () => {
        if (is) {
            dispatch({ type: 'remove', id: productId })
        } else {
            dispatch({ type: 'add', id: productId })
            setShow(true)
        }
    }

    return (
        <Button
            key={productId}
            icon={is ? 'check' : 'compare'}

            iconWidth={'sm'}
            iconVariant={is ? 'tp' : 'secondary'}

            variant={'white'}

            className={className ?? 'px-2'}

            onClick={handleClick}

            aria-label={t('Add to Compare', { name })}
        >
            {'extended' === variant && <span>{t('Vergleichen')}</span>}
        </Button>
    );
};

export default AddToCompareButton;
